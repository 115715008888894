<template>
  <div
    class="modal fade modal-advertising"
    id="advertisingModal"
    tabindex="-1"
    aria-labelledby="advertisingModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered justify-content-center">
      <div class="modal-content position-relative w-100 p-0">
        <button
          type="button"
          class="btn position-absolute end-0 top-0 p-1"
          data-bs-dismiss="modal"
          aria-label="Close"
        >
          <span class="material-symbols-outlined">cancel</span>
        </button>
        <div class="modal-body p-0" v-if="advertising.length">
          <VueSlickCarousel v-bind="slickSetup">
            <picture v-for="(ad, i) in advertising" :key="i">
              <source media="(min-width: 992px)" :srcset="ad.image_desktop" />
              <img :src="ad.image_mobile" :alt="ad.name" />
              <div class="text-center p-2">
                <p class="mb-3">{{ ad.description }}</p>
                <button
                  class="btn nav-link mx-auto fs-4"
                  type="button"
                  data-bs-dismiss="modal"
                >
                  Ver Menú
                </button>
              </div>
            </picture>
          </VueSlickCarousel>
        </div>
      </div>
    </div>

    <button
      ref="btnAdvertising"
      class="btn position-absolute top-0"
      data-bs-toggle="modal"
      data-bs-target="#advertisingModal"
    ></button>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

export default {
  name: "AdvertisingModal",
  components: {
    VueSlickCarousel,
  },
  created() {
    // Screen width size < 992
    if (window.innerWidth > 992) {
      this.isMobile = false;
    }
  },
  mounted() {
    if (this.advertising.length) {
      this.$refs.btnAdvertising.click();
    }
  },
  data() {
    return {
      isMobile: true,
      slickSetup: {
        arrows: false,
        dots: true,
        dotsClass: "custom-dots",
        centerMode: true,
        centerPadding: "0",
        infinite: true,
        autoplay: true,
        autoplaySpeed: 6000,
        cssEase: "linear",
        slidesToShow: 1,
        slidesToScroll: 1,
        pauseOnFocus: true,
        pauseOnHover: true,
      },
    };
  },
  props: {
    advertising: Array,
  },
  methods: {},
};
</script>

<style lang="scss">
.modal-advertising {
  .modal-content {
    max-width: 350px;
    border: 0;
    .slick-track {
      background: var(--secondary-bg);
      picture p {
        white-space: pre-line;
      }
    }
    .btn {
      z-index: 1;
      font-size: 2.4rem;
      color: var(--primary-font-cl);
    }
    .nav-link {
      color: var(--primary-bg);
      background-color: var(--primary);
      display: block;
      border-radius: 8px;
      padding: 5px 16px;
      font-weight: 600;
    }
  }
  .modal-dialog {
    max-width: initial;
  }
}
.custom-dots {
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  display: flex !important;
  justify-content: center;
  button {
    background: var(--secondary-font-cl);
    font-size: 0;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    border: 0;
    padding: 0px;
    margin: 5px;
  }
  .slick-active {
    button {
      background: var(--primary);
    }
  }
}

@media (min-width: $desktop-width) {
  .modal-advertising {
    .modal-content {
      max-width: 780px;
    }
  }
}
</style>