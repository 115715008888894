<template>
  <div>
    <div class="sk-carousel loading d-none d-lg-block"></div>
    <div class="row">
      <div class="d-flex flex-column align-items-center col-lg-4">
        <div class="sk-logo rounded-circle loading mt-5"></div>
        <div class="sk-subtitle rounded-3 loading mt-2"></div>
        <div class="sk-headline rounded-3 loading mt-1"></div>
        <div class="d-none d-lg-block mt-3">
          <ul>
            <li class="row align-items-center mb-1" v-for="i in 3" :key="i">
              <div class="sk-icon rounded-circle loading me-1"></div>
              <div class="sk-cat loading"></div>
            </li>
          </ul>
        </div>
      </div>
      <div class="d-lg-none">
        <ul class="d-flex mt-2 ps-1">
          <li class="sk-category loading me-1" v-for="i in 4" :key="i"></li>
        </ul>
      </div>
      <section class="d-lg-none p-0">
        <div class="sk-title rounded-3 loading mt-3 ms-2"></div>
        <ul class="d-flex px-2 mt-1 overflow-hidden">
          <li class="sk-promo loading me-1" v-for="i in 3" :key="i"></li>
        </ul>
      </section>
      <section class="ps-2 col-lg-8">
        <div class="sk-title rounded-3 loading mt-3"></div>
        <ul class="row mt-1 mx-1">
          <li class="sk-card col-12 col-md-6 mb-1 p-0" v-for="i in 3" :key="i">
            <div class="row me-md-2">
              <div class="col-4 loading"></div>
              <div class="sk-card-desc col-8 p-2">
                <div class="sk-card-title loading mb-1"></div>
                <div
                  class="sk-card-info loading mb-1"
                  v-for="i in 3"
                  :key="i"
                ></div>
              </div>
            </div>
          </li>
        </ul>
        <div class="sk-title rounded-3 loading mt-3"></div>
        <ul class="row mt-1 mx-1">
          <li class="sk-card col-12 col-md-6 mb-1 p-0" v-for="i in 3" :key="i">
            <div class="row me-md-2">
              <div class="col-4 loading"></div>
              <div class="sk-card-desc col-8 p-2">
                <div class="sk-card-title loading mb-1"></div>
                <div
                  class="sk-card-info loading mb-1"
                  v-for="i in 3"
                  :key="i"
                ></div>
              </div>
            </div>
          </li>
        </ul>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "SkeletonLoader",
  created() {},
  data() {
    return {};
  },
  props: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
@mixin set-size($w, $h) {
  width: $w;
  height: $h;
}

.sk-carousel {
  min-width: 900px;
  max-width: 1250px;
  min-height: 300px;
  max-height: 400px;
}
.sk-logo {
  @include set-size(156px, 156px);
}
.sk-subtitle {
  @include set-size(50px, 30px);
}
.sk-headline {
  @include set-size(200px, 40px);
}
.sk-title {
  @include set-size(200px, 30px);
}
.sk-category {
  @include set-size(80px, 40px);
  border-radius: 9px;
}
.sk-promo {
  @include set-size(150px, 230px);
  min-width: 150px;
}
.sk-card {
  height: 120px;
  border-radius: 8px;
  &-title {
    @include set-size(100px, 20px);
  }
  &-info {
    @include set-size(100%, 12px);
  }
}
.sk-icon {
  @include set-size(24px, 24px);
}
.sk-cat {
  @include set-size(200px, 20px);
}
</style>