<template>
  <div class="home ms-0 p-0">
    <!-- Error -->
    <div v-if="failed">
      <p>
        Lo sentimos, no es posible obtener la información en este momento, por
        favor intente nuevamente mas tarde
      </p>
    </div>
    <!-- Render -->
    <div class="p-0" v-else>
      <!-- Loading -->
      <SkeletonLoader v-if="loading" />
      <!-- Data -->
      <div class="overflow-hidden" v-else>
        <div class="content row px-1 px-lg-0">
          <aside class="col-lg-4 px-0">
            <SucursalInfo
              :class="`sticky-top hide-scrollbar`"
              :sucursal="sucursal"
              :families="productsInCategories"
              :isMobile="isMobile"
              @keyup="search"
              ref="sucInfo"
            />
          </aside>

          <main class="container col-lg-8 ps-0" ref="main">
            <SearchResult
              :class="!result.length ? 'max-height-0' : ''"
              :result="result"
              ref="searchResult"
              @click="getIndexProd"
            />
            <!-- <div
              v-if="!isMobile && promotions.length"
              style="text-align: center; width: 70%; margin: auto"
            >
              <h2 class="title ps-1 ps-md-0 mb-1">Promociones</h2>
              <div
                id="carouselExampleIndicators"
                class="carousel slide"
                data-bs-ride="carousel"
              >
                <div class="carousel-indicators">
                  <button
                    type="button"
                    data-bs-target="#carouselExampleIndicators"
                    v-for="(p, i) in promotions"
                    :key="i"
                    :data-bs-slide-to="i"
                    :class="{ active: i < 1 }"
                    :aria-current="i < 1 ? 'true' : ''"
                  ></button>
                </div>
                <div class="carousel-inner">
                  <div
                    class="carousel-item"
                    :class="{ active: i < 1 }"
                    v-for="(p, i) in promotions"
                    :key="i"
                  >
                    <img :src="p.banner" :alt="p.name" />
                  </div>
                </div>
                <button
                  class="carousel-control-prev"
                  type="button"
                  data-bs-target="#carouselExampleIndicators"
                  data-bs-slide="prev"
                >
                  <span
                    class="carousel-control-prev-icon"
                    aria-hidden="true"
                  ></span>
                  <span class="visually-hidden">Previous</span>
                </button>
                <button
                  class="carousel-control-next"
                  type="button"
                  data-bs-target="#carouselExampleIndicators"
                  data-bs-slide="next"
                >
                  <span
                    class="carousel-control-next-icon"
                    aria-hidden="true"
                  ></span>
                  <span class="visually-hidden">Next</span>
                </button>
              </div> -->
            <!-- <VueSlickCarousel
          :class="'d-none d-lg-block'"
          v-if="!isMobile && promotions.length"
          v-bind="slickSetup"
        >
          <img
            v-for="(promo, index) in promotions"
            :key="index"
            :src="promo.banner"
            :alt="promo.name"
          />
        </VueSlickCarousel> -->
            <!-- </div> -->
            <PromoGallery
              :promotions="promotions"
              v-if="promotions.length"
              @click="promoClick"
            />

            <!-- Recommended products -->
            <!-- <section
              v-if="recommendProducts.length"
              class="section position-relative pt-3"
            >
              <div
                class="limit position-absolute top-0"
                id="recommendedProducts"
              ></div>
              <h2 class="title px-1 px-md-0">Productos Recomendados</h2>
              <div class="products position-relative">
                <ul
                  class="row products-list ps-2 flex-nowrap flex-lg-wrap overflow-auto"
                >
                  <ProductCardSquare
                    v-for="(product, j) in recommendProducts"
                    :key="j"
                    :product="product"
                    @click="getIndexProd(j, true)"
                    :index="j"
                  />
                </ul>
              </div>
            </section> -->

            <ProductsList
              v-for="(category, index) in productsInCategories"
              :key="index"
              :prodByCat="category"
              @click="productClick"
              ref="list"
            />
          </main>
        </div>

        <MenuModal :families="productsInCategories" />
        <SucursalInfoModal :sucursal="sucursal" />
        <PromoModal
          v-if="promotions.length"
          :promo="promoClicked"
          :promotions="promotions"
          ref="promo"
        />
        <ProductModal
          :product="productClicked"
          :prodByCat="productsInCategories"
          :result="result"
        />
        <!-- :recProds="recommendProducts" -->
        <AdvertisingModal v-if="advertising" :advertising="advertising" />
      </div>
    </div>
    <!-- Google Tag Manager (noscript) -->
    <noscript>
      <iframe
        v-if="gtm_id && !loading"
        :src="`https://www.googletagmanager.com/ns.html?id=${gtm_id}`"
        height="0"
        width="0"
        style="display: none; visibility: hidden"
      ></iframe>
    </noscript>
    <!-- End Google Tag Manager (noscript) -->

    <noscript>
      <img
        v-if="facebook_pixel && !loading"
        height="1"
        width="1"
        style="display: none"
        :src="`https://www.facebook.com/tr?id=${facebook_pixel}&ev=PageView&noscript=1`"
      />
    </noscript>
  </div>
</template>

<script>
// @ is an alias to /src
import PromoGallery from "@/components/PromoGallery";
import SearchResult from "@/components/SearchResult";
import ProductsList from "@/components/ProductsList";
import SucursalInfo from "@/components/SucursalInfo";
import SkeletonLoader from "@/components/SkeletonLoader";
import AdvertisingModal from "@/components/AdvertisingModal";
// import ProductCardSquare from "@/components/ProductCardSquare";
// import VueSlickCarousel from "vue-slick-carousel";
// import "vue-slick-carousel/dist/vue-slick-carousel.css";
// import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
// Lazy or async components
// Only will be render if is requested to render in template
const ProductModal = () => import("@/components/ProductModal");
const PromoModal = () => import("@/components/PromoModal");
const MenuModal = () => import("@/components/MenuModal");
const SucursalInfoModal = () => import("@/components/SucursalInfoModal");

export default {
  name: "Home",
  created() {
    // Get sucursal ID from url
    this.sucId = location.pathname.split("/")[1];
    // Set id for api request
    this.$store.dispatch("loadSucursal", this.sucId).then(() => {
      const route = this.sucursal.route_key ? this.sucursal.route_key : "404";

      var firstElementHead = document.getElementsByTagName("meta")[1];

      if (this.sucursal.gtm_id) {
        const script = document.createElement("script");

        script.type = "text/javascript";
        script.innerText = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${this.sucursal.gtm_id}')`;

        document
          .getElementsByTagName("head")[0]
          .insertBefore(script, firstElementHead);

        this.gtm_id = this.sucursal.gtm_id;
      }

      if (this.sucursal.facebook_pixel) {
        const script = document.createElement("script");

        script.type = "text/javascript";
        script.innerText = `!function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window, document,'script','https://connect.facebook.net/en_US/fbevents.js');fbq('init', '${this.sucursal.facebook_pixel}');fbq('track', 'PageView');`;

        document
          .getElementsByTagName("head")[0]
          .insertBefore(script, firstElementHead);

        this.facebook_pixel = this.sucursal.facebook_pixel;
      }

      // if (this.sucursal.google_analytics) {
      //   const scriptLink = document.createElement("script");

      //   scriptLink.async = true;
      //   scriptLink.src = `https://www.googletagmanager.com/gtag/js?id=${this.sucursal.google_analytics}`;

      //   const script = document.createElement("script");

      //   script.type = "text/javascript";
      //   script.innerText = `window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date());gtag('config', '${this.sucursal.google_analytics}');`;

      //   document
      //     .getElementsByTagName("head")[0]
      //     .insertBefore(scriptLink, firstElementHead);
      //   document
      //     .getElementsByTagName("head")[0]
      //     .insertBefore(script, firstElementHead);
      // }

      var fontsSection = document.getElementsByTagName("link")[9];

      if (this.sucursal.fonts.headline) {
        const googleFont = document.createElement("link");

        googleFont.rel = "stylesheet";
        googleFont.href = `https://fonts.googleapis.com/css2?family=Agdasima&family=${this.sucursal.fonts.headline.replace(
          / /g,
          "+"
        )}&display=swap`;

        document
          .getElementsByTagName("head")[0]
          .insertBefore(googleFont, fontsSection);
      }

      if (this.sucursal.fonts.body) {
        const googleFont = document.createElement("link");

        googleFont.rel = "stylesheet";
        googleFont.href = `https://fonts.googleapis.com/css2?family=Agdasima&family=${this.sucursal.fonts.body.replace(
          / /g,
          "+"
        )}&display=swap`;

        document
          .getElementsByTagName("head")[0]
          .insertBefore(googleFont, fontsSection);
      }

      history.pushState({}, "", "/" + route);

      document.title = `${this.sucursal.company} - ${this.sucursal.name}`;
    });

    // Screen width size < 992
    if (window.innerWidth > 992) {
      this.isMobile = false;
    }
  },
  mounted() {
    window.addEventListener("scroll", () => {
      if (!this.isMobile) {
        if (window.scrollY >= this.$refs.main.offsetTop - 10) {
          this.$refs.main.style = "overflow-y: auto";
        } else {
          this.$refs.main.style = "";
        }
      }
    });
  },
  computed: {
    sucursal() {
      return this.$store.getters.sucursal;
    },
    promotions() {
      return JSON.parse(this.$store.getters.promotions);
    },
    advertising() {
      return this.$store.getters.advertising;
    },
    categories() {
      return this.$store.getters.categories;
    },
    products() {
      return this.$store.getters.products;
    },
    // recommendProducts() {
    //   return this.products.filter((p) => p.recommended);
    // },
    loading() {
      return this.$store.getters.loading;
    },
    failed() {
      return this.$store.getters.error;
    },
    productsInCategories() {
      if (this.categories.length) {
        const prdInCat = this.categories.map((f) => {
          const categories = f.categories.map((c) => {
            const products = this.products.filter(
              (p) => p.category_id === c.id
            );
            const recommended = this.products.filter(
              (p) => p.recommended && p.category_id === c.id
            );
            return { ...c, products, recommended };
          });

          const catFiltered = categories.filter((c) => c.products.length);

          // const featured = this.products.filter((p) => p.featured);

          return { ...f, categories: catFiltered };
        });

        return prdInCat.filter((f) => f.categories.length);
      }

      return [];
    },
    result() {
      if (this.searchString) {
        return this.products.filter((p) => {
          const productName = p.name
            .toLowerCase()
            .normalize("NFD")
            .replace(/\p{Diacritic}/gu, "");
          const search = this.searchString
            .toLowerCase()
            .normalize("NFD")
            .replace(/\p{Diacritic}/gu, "");

          return productName.includes(search);
        });
      }

      return [];
    },
  },
  components: {
    SucursalInfo,
    SearchResult,
    // VueSlickCarousel,
    PromoGallery,
    PromoModal,
    SkeletonLoader,
    MenuModal,
    ProductsList,
    ProductModal,
    AdvertisingModal,
    SucursalInfoModal,
    // ProductCardSquare,
  },
  data() {
    return {
      searchString: "",
      sucId: "",
      gtm_id: null,
      facebook_pixel: null,
      promoClicked: { index: 0 },
      productClicked: null,
      isMobile: true,
      slickSetup: {
        arrows: false,
        centerMode: true,
        centerPadding: "0",
        edgeFriction: 0.35,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 4000,
        cssEase: "linear",
        slidesToShow: 1,
        slidesToScroll: 1,
        pauseOnFocus: true,
        pauseOnHover: true,
      },
    };
  },
  methods: {
    search(value) {
      this.$refs.searchResult.$el.scrollIntoView();
      this.searchString = value;
    },
    promoClick(index) {
      this.promoClicked = { index };
    },
    getIndexProd(index, recommended) {
      this.productClicked = { index, recommended };
    },
    productClick(props) {
      this.searchString = "";
      this.productClicked = props;
    },
  },
};
</script>

<style lang="scss" scoped>
main {
  overflow: auto;
}
@media (min-width: $desktop-width) {
  .carousel-control-prev,
  .carousel-control-next {
    width: 10%;
    &:hover {
      background: rgba(0, 0, 0, 0.5);
      opacity: 1;
    }
  }
  .content {
    height: 100vh;
    main {
      height: 100%;
      // overflow: hidden;
    }
  }
}
</style>
