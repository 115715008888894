<template>
  <!-- prettier-ignore -->
  <li class="col-8 col-md-5 ps-0 pe-1 p-md-0"
    data-bs-toggle="modal"
    data-bs-target="#modalProduct"
    @click="productClick({ prodId: product.id, recommended: true })">
    <div class="product mt-1 me-md-1">
      <div class="product-image p-0">
        <img class="lazy-load" :src="product.thumbnail" :alt="product.name" />
      </div>
      <div class="product-details position-relative p-2 pe-1 pb-1 md-min-height">
        <div class="d-flex justify-content-between mb-1 align-items-center">
          <span class="badge rounded-pill">Recomendado</span>

          <ul class="d-flex">
            <li class="tag-dot rounded-circle" v-for="(t, i) in maxTags" :key="i"><span :class="t.icon"></span></li>
            <li class="tag-dot rounded-circle fw-bold" v-if="moreTags.length"><span style="margin-left: -2px;">+{{ moreTags.length }}</span></li>
          </ul>
        </div>

        <div class="d-flex justify-content-between align-items-end mb-1">
          <span class="product-title col-12"
            v-html="
              `${product.name}`
            "></span>
        </div>
        <p class="product-description mb-0" v-if="product.description">{{product.description}}</p>
        <ul class="product-description mb-0" v-else>
          <!-- <li v-for="g, index in product.variant_groups" :key="index" :class="variant.enabled === false?'d-none':''"> -->
          <li v-for="g, index in product.variant_groups" :key="index" :class="!g.variants.length?'d-none':''">
            <p>{{ g.name }}</p>
            <small v-for="v, i in g.variants" :key="i" :class="!v.enabled && v.enabled !== undefined?'d-none':'d-block'">
              {{ v.name }} {{v.price?`$${v.price}` : ''}}
            </small>
          </li>
        </ul>
        <div class="d-flex justify-content-between align-items-center mt-1">
          <span class="product-price d-block col-2">${{ product.price }}</span>
          <button  class="btn btn-outline rounded-pill">Ver más</button>
        </div>
      </div>
    </div>
  </li>
</template>

<script>
export default {
  name: "ProductCard",
  props: {
    product: Object,
    subcatId: Number,
  },
  mounted() {
    // Screen width size < 992
    if (window.innerWidth > 992) {
      this.isMobile = false;
    }
  },
  data() {
    return {
      isMobile: true,
    };
  },
  computed: {
    maxTags() {
      const tagLimit = this.isMobile ? 2 : 3;
      const tags = [...this.product.tags];

      return tags.splice(0, tagLimit);
    },
    moreTags() {
      const tagLimit = this.isMobile ? 2 : 3;
      const tags = [...this.product.tags];

      tags.splice(0, tagLimit);

      return tags;
    },
  },
  methods: {
    productClick(props) {
      this.$emit("click", props);
    },
  },
};
</script>

<style lang="scss" scoped>
.md-min-height {
  min-height: 151px;
}
.badge {
  background-color: var(--primary);
  color: var(--primary-bg);
}
.btn-outline {
  border-color: var(--primary);
  color: var(--primary);
  height: 2.8rem;
}
.tag-dot {
  background-color: var(--primary-bg);
  color: var(--secondary-font-cl);
  display: flex;
  margin-left: 4px;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
}
.product {
  min-height: 120px;
  background-color: var(--secondary-bg);
  border-radius: 8px;
  box-shadow: 5px 8px 15px -10px rgba(0, 0, 0, 0.25);
  &-image {
    border-radius: 8px;
    img {
      border-radius: 8px 0 0 8px;
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
  }
  &-title {
    font-size: $ft-sm;
    font-weight: 600;
    line-height: 1;
  }
  &-description {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    height: 55px;
  }
  &-price {
    font-family: var(--headline-font);
    font-size: 1.8em;
    // text-align: end;
    // line-height: 1;
  }
}

@media (min-width: 992px) {
  .md-min-height {
    min-height: initial;
  }
}
</style>