<template>
  <!-- prettier-ignore -->
  <header class="sucursal" ref="sucursal">
    <div class="sucursal-info d-flex flex-column
      align-items-center pt-5" ref="hero" >
      <div class="sucursal-image">
        <img class="rounded-circle" data-bs-toggle="modal" data-bs-target="#sucursalInfoModal" :src="sucursal.logo || require('@/assets/images/profile.png')"
          :alt="`${sucursal.company} ${sucursal.name}`"/>
      </div>
      <div class="sucursal-title text-center">
        <h2 class="subtitle mt-2 m-auto">{{sucursal.company}}</h2>
        <h1 class="headline">{{sucursal.name}}</h1>
      </div>

      <!-- More sucursal info -->
      <div class="info-nav nav-pills d-lg-none sucursal-info-slide hide-scrollbar pt-1 w-100 px-1 overflow-auto">
        <div class="btn nav-item fs-4">
          <button class="nav-link w-100 justify-content-center" data-bs-toggle="modal" data-bs-target="#sucursalInfoModal">Información del negocio</button>
        </div>
        <!-- <ul class="info-nav nav-pills d-flex m-0 flex-wrap">
            <li class="nav-item me-1 mt-1" :class="itm.parentClass" v-for="(itm, index) in info" :key="index" v-show="itm.value || itm.type === 'triggerModal'">
              <div class="nav-link" v-if="itm.type === 'triggerModal'" data-bs-toggle="modal" :data-bs-target="itm.target">
                <span :class="{'me-1': itm.label}"  v-html="itm.icon"></span>
                {{itm.label}}
              </div>
              <a class="nav-link" :class="itm.class" v-else :href="`${itm.value}`" target="_blank">
                <span :class="{'me-1': itm.label}" :style="!itm.label?'font-size: 21px':''"  v-html="itm.icon"></span>
                {{itm.label}}
              </a>
            </li>
          </ul> -->
      </div>
      <div class="info-nav d-none d-lg-block sucursal-info-slide hide-scrollbar pt-1 w-100 px-1 overflow-auto">
        <div class="nav-item me-1" v-if="info[0].value">
          <a class="nav-link d-flex align-items-center" :href="`${info[0].value}`" target="_blank">
            <span class="me-1" v-html="info[0].icon"></span>
            <span class="fs-3">{{info[0].label}}</span>
          </a>
        </div>
        <ul class="d-flex flex-wrap">
          <div class="nav-item me-1 mt-1" v-if="info[1].value">
            <a class="nav-link d-flex align-items-center me-1" :href="`${info[1].value}`" target="_blank">
              <span class="me-1" v-html="info[1].icon"></span>
              <span class="fs-3">{{info[1].label}}</span>
            </a>
          </div>
          <div class="nav-item me-1 mt-1" v-if="info[2].value">
            <a class="nav-link d-flex align-items-center" :href="`${info[2].value}`" target="_blank">
              <span class="fs-2" v-html="info[2].icon"></span>
            </a>
          </div>
          <div class="nav-item me-1 mt-1" v-if="info[3].value">
              <a class="nav-link d-flex align-items-center" :href="`${info[3].value}`" target="_blank">
              <span class="fs-2" v-html="info[3].icon"></span>
            </a>
            </div>
            <div class="nav-item me-1 mt-1" v-if="info[4].value">
              <a class="nav-link d-flex align-items-center" :href="`${info[4].value}`" target="_blank">
              <span class="fs-2" v-html="info[4].icon"></span>
            </a>
              </div>
            <div class="nav-item me-1 mt-1" v-if="info[5].value">
              <a class="nav-link d-flex align-items-center" :href="`${info[5].value}`" target="_blank">
              <span class="fs-2" v-html="info[5].icon"></span>
            </a>
              </div>
            <div class="nav-item me-1 mt-1">
              <dev class="nav-link d-flex align-items-center" data-bs-toggle="modal" data-bs-target="#sucursalInfoModal">
                <span class="material-symbols-outlined info-i me-1">
                  info_i
                </span>
                Ver más
              </dev>
            </div>
        </ul>
      </div>
    </div>

    <Navbar
    :info="info"
    :sucursal="sucursal"
      :families="families"
      :isHeroOut="isHeroOut"
      :isMobile="isMobile"
      @keyup="search" ref="navbar"
    />
    <a class="material-icons position-fixed search-icon d-lg-none"
      href="#searchResult" ref="search" v-if="isMobile"
      :style="`${isHeroOut ? 'display: none' : ''}`"
      @click="activeSearchBar"
    >search</a>
  </header>
</template>

<script>
import Navbar from "@/components/Navbar";

export default {
  name: "SucursalInfo",
  components: {
    Navbar,
  },
  mounted() {
    window.addEventListener("scroll", () => {
      this.isHeroOut = scrollY > this.$refs.hero.clientHeight - 100;
    });
  },
  data() {
    return {
      isHeroOut: false,
    };
  },
  props: {
    sucursal: Object,
    families: Array,
    isMobile: Boolean,
  },
  computed: {
    info() {
      return [
        {
          icon: '<span class="material-symbols-outlined">location_on</span>',
          label: "Dirección",
          parentClass: "w-100",
          class: "justify-content-center",
          value: this.sucursal.address
            ? `https://www.google.com/maps/search/${this.sucursal.address.replace(
                / /g,
                "+"
              )}`
            : "",
        },
        {
          icon: '<span class="material-symbols-outlined">call</span>',
          label: this.sucursal.phone,
          value: this.sucursal.phone ? `tel:${this.sucursal.phone}` : "",
          parentClass: "w-100",
          class: "justify-content-center",
        },
        {
          icon: '<span class="icon-whatsapp" style="line-height: inherit"></span>',
          value: this.sucursal.networks.whatsapp,
        },
        {
          icon: '<span class="icon-facebook" style="line-height: inherit"></span>',
          value: this.sucursal.networks.facebook,
        },
        {
          icon: '<span class="icon-instagram" style="line-height: inherit"></span>',
          value: this.sucursal.networks.instagram,
        },
        {
          icon: '<span class="icon-tiktok" style="line-height: inherit"></span>',
          value: this.sucursal.networks.tiktok,
        },
        {
          icon: '<span class="material-symbols-outlined info-i">info_i</span>',
          type: "triggerModal",
          target: "#sucursalInfoModal",
          label: "Ver más",
        },
      ];
    },
  },
  methods: {
    search(value) {
      this.$emit("keyup", value);
    },
    activeSearchBar() {
      this.$refs.navbar.$refs.searchBtn.click();
    },
  },
};
</script>

<style lang="scss" scoped>
.info-i {
  font-size: 1.6rem;
  border: 2px solid var(--primary-font-cl);
  border-radius: 50%;
}
.sucursal {
  min-height: 364px;
  position: relative;
  &-info {
    min-height: 300px;
  }
  &-image img {
    width: 156px;
    height: 156px;
  }
  &-title {
    .headline {
      min-height: 40px;
    }
    .subtitle {
      min-height: 30px;
    }
  }
  .search-icon {
    top: 16px;
    right: 16px;
    width: 32px;
    height: 32px;
    text-align: center;
  }
  .info-icon {
    top: 16px;
    left: 16px;
    width: 32px;
    height: 32px;
    text-align: center;
  }
  .info-nav {
    .nav-item {
      background: var(--secondary-bg);
      color: var(--primary-font-cl);
      display: block;
      border-radius: 8px;
    }
    .nav-link {
      white-space: nowrap;
      display: flex;
      align-items: center;
      color: var(--primary-font-cl);
      &:active,
      &:hover,
      &:focus {
        background: var(--secondary-bg);
        color: var(--primary-font-cl);
      }
    }
  }
}
@media (min-width: $desktop-width) {
  .sucursal {
    height: 100vh;
  }
}
</style>