import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import { menu } from "../config";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    sucursal: {},
    promotions: [],
    advertising: [],
    categories: [],
    products: [],
    loading: true,
    error: false,
  },
  getters: {
    sucursal: (state) => {
      return state.sucursal;
    },
    promotions: (state) => {
      return state.promotions;
    },
    advertising: (state) => {
      return state.advertising;
    },
    categories: (state) => {
      return state.categories;
    },
    products: (state) => {
      return state.products;
    },
    loading: (state) => {
      return state.loading;
    },
    error: (state) => {
      return state.error;
    },
  },
  mutations: {
    SET_SUCURSAL(state, sucursal) {
      state.sucursal = sucursal;
    },
    SET_PROMOTIONS(state, promotions) {
      state.promotions = promotions;
    },
    SET_ADVERTISING(state, advertising) {
      state.advertising = advertising;
    },
    SET_CATEGORIES(state, categories) {
      state.categories = categories;
    },
    SET_PRODUCTS(state, products) {
      state.products = products;
    },
    SET_LOADING(state, loading) {
      state.loading = loading;
    },
    SET_ERROR(state, error) {
      state.error = error;
    },
  },
  actions: {
    async loadSucursal({ commit, state }, id) {
      const uuidReg =
        /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/;
      let sucursal;

      try {
        if (uuidReg.test(id)) {
          sucursal = await axios.get(`${menu.api}/v1/sucursales/${id}`);
        } else {
          sucursal = await axios.get(`${menu.api}/v1/sucursales/url/${id}`);
        }

        if (sucursal.data.fonts.headline) {
          document.documentElement.style.setProperty(
            "--headline-font",
            `"${sucursal.data.fonts.headline}", Arial, sans-serif`
          );
        }
        if (sucursal.data.fonts.body) {
          document.documentElement.style.setProperty(
            "--body-font",
            `"${sucursal.data.fonts.body}", Arial, sans-serif`
          );
        }

        console.log("sucursal", sucursal);
        document.documentElement.style.setProperty(
          "--primary",
          sucursal.data.theme.button
        );
        document.documentElement.style.setProperty(
          "--primary-bg",
          sucursal.data.theme.background
        );
        document.documentElement.style.setProperty(
          "--secondary-bg",
          hexToHSL(sucursal.data.theme.background, 6)
        );
        document.documentElement.style.setProperty(
          "--primary-font-cl",
          sucursal.data.theme.font
        );
        document.documentElement.style.setProperty(
          "--secondary-font-cl",
          hexToHSL(sucursal.data.theme.font, 0, 0.75)
        );
        commit("SET_SUCURSAL", sucursal.data);

        const promotions = await axios.get(state.sucursal.promotions);
        const promotionsEnabled = promotions.data.filter(
          (p) => p.enabled || p.enabled === undefined
        );
        commit("SET_PROMOTIONS", JSON.stringify(promotionsEnabled));

        const advertising = await axios.get(state.sucursal.advertising);
        const advertisingEnabled = advertising.data.filter(
          (a) =>
            a.enabled &&
            (!a.expiration || new Date(a.expiration) / 1000 > new Date() / 1000)
        );
        commit("SET_ADVERTISING", advertisingEnabled);

        console.log("advertising.data", advertising.data);
        console.log("advertisingEnabled", advertisingEnabled);

        const categories = await axios.get(state.sucursal.categories);
        commit("SET_CATEGORIES", categories.data);

        const products = await axios.get(state.sucursal.products);
        const productsEnabled = products.data.filter(
          (p) => p.enabled || p.enabled === undefined
        );
        commit("SET_PRODUCTS", productsEnabled);

        console.log(products.data.find((p) => p.name.includes("Chela")));

        commit("SET_LOADING", false);
      } catch (error) {
        console.log(error);
        commit("SET_ERROR", true);
      }
    },
  },
  modules: {},
});

function hexToHSL(H, L, A) {
  // Convert hex to RGB first
  let r = 0,
    g = 0,
    b = 0;
  if (H.length == 4) {
    r = "0x" + H[1] + H[1];
    g = "0x" + H[2] + H[2];
    b = "0x" + H[3] + H[3];
  } else if (H.length == 7) {
    r = "0x" + H[1] + H[2];
    g = "0x" + H[3] + H[4];
    b = "0x" + H[5] + H[6];
  }
  // Then to HSL
  r /= 255;
  g /= 255;
  b /= 255;
  let cmin = Math.min(r, g, b),
    cmax = Math.max(r, g, b),
    delta = cmax - cmin,
    h = 0,
    s = 0,
    l = 0;

  if (delta == 0) h = 0;
  else if (cmax == r) h = ((g - b) / delta) % 6;
  else if (cmax == g) h = (b - r) / delta + 2;
  else h = (r - g) / delta + 4;

  h = Math.round(h * 60);

  if (h < 0) h += 360;

  l = (cmax + cmin) / 2;
  s = delta == 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
  s = +(s * 100).toFixed(1);
  l = +(l * 100).toFixed(1);

  if (L !== 0) l += L;

  return A
    ? "hsl(" + h + "," + s + "%," + l + "%, " + A + ")"
    : "hsl(" + h + "," + s + "%," + l + "%)";
}
