<template>
  <!-- prettier-ignore -->
  <section class="search-result position-relative pt-lg-3"
    id="searchResult">
    <h2 class="title px-1 mt-1 pt-lg-0">Productos encontrados</h2>
    <ul class="row products-list ps-2" v-if="result.length > 0">
      <ProductCard
        v-for="(product, index) in result"
        :key="product.id" :index="index"
        :product="product"
        @click="getIndexProd(index)"
      />
    </ul>
    <p class="fs-2 text-center pt-3" v-else>
      No se encontró el producto
    </p>
  </section>
</template>

<script>
import ProductCard from "@/components/ProductCard";
export default {
  name: "SearchResult",
  components: {
    ProductCard,
  },
  props: {
    result: Array,
  },
  methods: {
    getIndexProd(index) {
      this.$emit("click", index);
    },
  },
};
</script>

<style lang="scss" scoped>
.search-result {
  .title {
    padding-top: 96px;
  }
  height: auto;
  max-height: 4000px;
  overflow: hidden;
  transition: max-height 1s;
}
</style>